<template>
  <section>
    <v-row class="mx-0">
      <v-col>
        <p class="text-h6 fosnt-museo-sans secondary--text" id="titulo">
          Perfil de proveedor - Agregar
          {{ type == "OBS" ? "OBS" : "giro empresarial" }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="">
        <v-row>
          <v-col cols="6">
            <v-tooltip
              open-on-focus
              :right="$vuetify.breakpoint.mdAndUp"
              :top="!$vuetify.breakpoint.mdAndUp"
              color="info"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-col cols="12">
                    <v-autocomplete
                      :items="optionsFilter"
                      :label="type == 'OBS' ? 'OBS' : 'Giro empresarial ' + '*'"
                      :loading="isLoading"
                      @click:clear="option = null"
                      @keyup="isLoading = true"
                      @update:search-input="obsList"
                      class="mx-0"
                      clearable
                      :item-text="type == 'OBS' ? 'codigoNombre' : 'nombre'"
                      item-value="id"
                      outlined
                      v-bind="$attrs"
                      v-model="option"
                      hide-no-data
                    >
                      <template
                        v-if="type === 'OBS'"
                        v-slot:[`item`]="{ item }"
                      >
                        {{ item.codigo + " - " + item.nombre }}
                      </template>
                    </v-autocomplete>
                    <template
                      v-if="
                        exist_search &&
                        type == 'OBS' &&
                        optionsFilter.length == 0
                      "
                    >
                      <v-alert
                        dense
                        text
                        type="warning"
                        transition="slide-x-transition"
                      >
                        No se encontraron coincidencias, por favor intente con
                        otra búsqueda.
                      </v-alert>
                    </template>
                  </v-col>
                </div>
              </template>
              <span v-if="type == 'OBS'"
                >Escriba el nombre del OBS y luego selecciónelo</span
              >
              <span v-else
                >Escriba el nombre del giro empresarial y luego
                selecciónelo</span
              >
            </v-tooltip>
            <v-col v-if="option !== null && type === 'OBS'" cols="12">
              <!-- Formulario para agregar titulo y descripcion al OBS -->
              <v-form>
                <v-text-field
                  v-model="obsForm.title"
                  label="Título *"
                  outlined
                  required
                  class="mt-3"
                  :error-messages="obsTitleError"
                  @input="$v.obsForm.title.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="obsForm.description"
                  label="Descripción *"
                  outlined
                  required
                  class="mt-3"
                  :error-messages="obsDescriptionError"
                  @input="$v.obsForm.description.$touch()"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>
        <v-row no-gutters class="mb-4">
          <v-col cols="6" class="ml-4">
            <v-btn
              @click="
                type === 'GIRO'
                  ? insertOptionGiro(option)
                  : insertOptionOBS(option)
              "
              dark
              color="secondary"
              v-if="option != null"
            >
             {{ type === 'GIRO' ? "Agregar" : "Agregar OBS a listado" }}
            </v-btn>
          </v-col>
        </v-row>

        <v-col cols="12">
          <p v-if="type === 'OBS'" class="primary--text">Listado de OBS</p>
          <div v-if="type === 'GIRO'" class="d-flex align-center">
            <p v-if="type === 'GIRO'" class="primary--text">Listado de giros</p>

            <v-dialog width="500" v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-4"
                  color="capres"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <br />
                  <p>
                    Como máximo podrá seleccionar y asignar 3 giros a su empresa
                  </p>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <data-table-component
            v-models:select="perPage"
            v-models:pagina="page"
            :headers="headers"
            :items="optionsList"
            @paginar="paginate"
            :total_registros="totalRows"
            :tiene_paginacion="type === 'GIRO' ? false : true"
          >
            <template
              v-if="type === 'OBS'"
              v-slot:[`item.obs_nombre`]="{ item }"
            >
              {{ item.obs_codigo }} - {{ item.obs_nombre }}
            </template>

            <template v-if="type === 'OBS'" v-slot:[`item.actions`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="configuration(item)">
                    mdi-cog-outline
                  </v-icon>
                </template>
                <span> Configurar </span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="onShowDeleteDialog(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span> Eliminar </span>
              </v-tooltip>
            </template>
            <template
              v-else-if="type === 'GIRO'"
              v-slot:[`item.actions`]="{ item }"
            >
              <!-- Tooltip -->
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="onShowDeleteDialog(item)"
                    >mdi-delete-outline</v-icon
                  >
                </template>
                <span> Eliminar </span>
              </v-tooltip>
            </template>
          </data-table-component>
          <ConfirmationDialog
            :show="showDeleteDialog"
            title="¿Desea remover el elemento seleccionado?"
            message="De confirmarse la siguiente acción, los cambios no serán reversibles."
            @confirm="deleteOption"
            @close="showDeleteDialog = false"
          />
        </v-col>

        <v-col cols="12">
          <v-btn class="mt-4" outlined @click="$router.back()">
            Regresar
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ConfirmationDialog from "../../../components/ConfirmationDialogComponent.vue";
import { required, helpers, maxLength } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
import AppPaginationComponent from "../../../components/AppPaginationComponent.vue";
import DataTableComponent from "../../../components/DataTableComponent.vue";

const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ÿ0-9-,/()\u00f1\u00d1 ]*$/);

export default {
  name: "AgregarListaComponent",
  props: {},
  components: {
    ConfirmationDialog,
    AppPaginationComponent,
    DataTableComponent,
  },
  data: () => ({
    totalRows: 0,
    page: 1,
    perPage: 10,
    obsForm: {
      title: "",
      description: "",
    },
    showDeleteDialog: false,
    deleteItem: null,
    typeList: ["OBS", "GIRO"],
    type: null,
    options: [],
    option: null,
    Giro: [],
    ListaActividad: [],
    headers: [],
    optionsList: [],
    dialog: false,
    isLoading: false,
    obs_timer: null,
    exist_search: null,
  }),
  validations: {
    obsForm: {
      title: { required, alpha, maxLength: maxLength(50) },
      description: { required, alpha, maxLength: maxLength(50) },
    },
  },
  methods: {
    ...mapMutations("proveedores", ["setObsInfo"]),
    async insertOptionGiro(option) {
      let objgiro = {
        giros_empresariales: [option],
      };
      const response = await this.services.Proveedores.postBusinessActivities(
        objgiro
      );

      if (response.status == 201) {
        this.temporalAlert({
          show: true,
          message: "Giro empresarial agregado correctamente",
          type: "success",
        });

        this.option = null;
      }

      await this.getListActivities();
      if (this.optionsList.length > 3) {
        this.temporalAlert({
          show: true,
          message:
            "Solo se podrá seleccionar 3 giros empresariales como máximo",
          type: "error",
        });
      }
    },
    async insertOptionOBS(option) {
      // Verificamos el estado del formulario para agregar el OBS
      this.$v.obsForm.$touch();

      // Si el formulario es valido, agregamos el OBS
      if (!this.$v.obsForm.$invalid) {
        // Creamos el objeto para agregar el OBS
        const obs = {
          id_producto: option,
          titulo: this.obsForm.title,
          descripcion: this.obsForm.description,
        };

        // Intentamos agregar el OBS
        const response =
          await this.services.ProveedoresProcuctos.postObsProvider(obs);
        if (response.status == 201) {
          this.temporalAlert({
            show: true,
            message: "OBS agregado correctamente",
            type: "success",
          });
          // Limpiamos el formulario
          this.option = null;
          this.obsForm.title = "";
          this.obsForm.description = "";

          // Obtenemos el listado de OBS
          this.getListProducts();
        } else {
          this.temporalAlert({
            show: true,
            message: "Ocurrió un error al agregar el OBS",
            type: "error",
          });
        }
      }
    },
    /**
     * Muestra un mensaje de confirmacion antes de eliminar un registro
     * @param {*} item
     */
    async onShowDeleteDialog(item) {
      this.showDeleteDialog = true;
      this.deleteItem = item;
    },
    async deleteOption() {

      if (this.type === "GIRO") {
        const response =
          await this.services.Proveedores.deleteBusinessActivities(
            this.deleteItem.ProveedorGiroEmpresarial.id
          );

        if (response.status == 200) {
          this.temporalAlert({
            show: true,
            message: "Giro empresarial eliminado correctamente",
            type: "success",
          });
          this.optionsList = this.optionsList.filter(
            (option) => option.nombre !== this.deleteItem.nombre
          );
        }
      }

      if (this.type === "OBS") {
        const response =
          await this.services.ProveedoresProcuctos.deleteObsProvider(
            this.deleteItem.id
          );

        if (response.status == 204) {
          this.temporalAlert({
            show: true,
            message: "OBS eliminado correctamente",
            type: "success",
          });

          this.getListProducts({ page: 1, per_page: 10 });
        }
      }

      this.deleteItem = null;
      this.showDeleteDialog = false;
    },
    configuration(item) {
      this.setObsInfo(item);
      this.$router.push({ name: "configuracion-obs", params: { id: item.id } });
    },
    goBack() {
      this.$router.push({ name: "perfil_proveedor" });
    },
    async giroempresaial() {
      const response =
        await this.services.BusinessActivities.getActividadEmpresarial();
      this.Giro = response.data;
    },
    async getListActivities() {
      const response =
        await this.services.Proveedores.getBusinessActivitiesList();
      this.optionsList = response.data;
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.page = pagina;
      this.perPage = cantidad_por_pagina;
      this.getListProducts({ page: this.page, per_page: this.perPage });
    },
    async getListProducts(filters = {}) {
      const response = await this.services.ProveedoresProcuctos.getObsProvider(
        filters
      );
      this.optionsList = response.data;
      this.totalRows = Number(response.headers.total_rows);
    },
    async obsList(e) {
      // const response = await this.services.Proveedores.getObs({
      //   pagination: false,
      //   productos: true,
      // });
      // this.options = response.data;

      this.exist_search = e;

      if (this.obs_timer) {
        clearTimeout(this.obs_timer);
      }
      if (this.option > 0) {
        return;
      }

      this.obs_timer = setTimeout(async () => {
        let params = {
          search: e,
          inactivos: false,
        };

        let codigoObs = Number(e);

        if (
          (codigoObs && codigoObs != NaN && e?.length > 3) ||
          (e?.length > 2 && isNaN(codigoObs))
        ) {
          const { data } = await this.services.Obs.getObsList(params);
          const elements = [];
          data.forEach((obs) => {
            elements.push({
              id: obs.id,
              codigo: obs.codigo,
              nombre: obs.nombre,
              codigoNombre: `${obs.codigo} - ${obs.nombre}`,
            });
          });
          this.options = elements;
        }
        this.isLoading = false;
      }, 500);
    },
  },
  computed: {
    /**
     * Filtrar opciones del select
     *
     * No se mostraran en el select las opciones que ya se han seleccionado
     */
    optionsFilter() {
      return this.options.filter((option) => {
        if (this.type == "OBS") {
          return !this.optionsList.some((item) => item.obs_id === option.id);
        } else {
          return !this.optionsList.some((item) => item.id === option.id);
        }
      });
    },
    // Mensajes de error para validaciones de formulario
    obsTitleError() {
      const errors = [];
      if (!this.$v.obsForm.title.$dirty) return errors;
      !this.$v.obsForm.title.required && errors.push("El título es requerido");
      !this.$v.obsForm.title.alpha &&
        errors.push("Título solo permite caracteres alfanuméricos");
      !this.$v.obsForm.title.maxLength &&
        errors.push("Título solo permite 50 caracteres como máximo");

      return errors;
    },
    obsDescriptionError() {
      const errors = [];
      if (!this.$v.obsForm.description.$dirty) return errors;
      !this.$v.obsForm.description.required &&
        errors.push("La descripción es requerida");
      !this.$v.obsForm.description.alpha &&
        errors.push("Descripción solo permite caracteres alfanuméricos");
      !this.$v.obsForm.description.maxLength &&
        errors.push("Descripción solo permite 50 caracteres como máximo");
      return errors;
    },
  },
  async created() {
    this.type = this.$route.params.type;
    if (!this.typeList.includes(this.type)) {
      this.$router.push("/");
    }
    // Obtenemos la informacion de obs
    if (this.type === "OBS") {
      this.headers = [
        { text: "OBS", align: "start", value: "obs_nombre" },
        { text: "Título", align: "start", value: "titulo" },
        { text: "Descripción", align: "start", value: "descripcion" },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ];

      // await this.obsList();
      await this.getListProducts({ page: this.page, per_page: this.perPage });
    }
    // Obtenemos la informacion de giro empresarial
    if (this.type === "GIRO") {
      this.headers = [
        { text: "Nombre", align: "start", value: "nombre" },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ];
      await this.giroempresaial();
      this.getListActivities();
      this.options = this.Giro;
    }
  },
};
</script>
